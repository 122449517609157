@import url("https://fonts.googleapis.com/css?family=Fjalla+One");

/* //////////////////////////////////////////// */
/*  Elements                                */
/* //////////////////////////////////////////// */

html,
body {
  min-width: 320px;
}

main.container-fluid {
  padding: 0;
  margin: 0;
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
li {
  color: #5b5b5b;
  font-family: "Fjalla One";
}

*:hover {
  transition: all 0.3s ease-in-out;
}

p {
  font-family: "Fjalla One";
  font-weight: 300;
  font-size: 1.1em;
  color: #5b5b5b;
  -webkit-font-smoothing: antialiased;
}

p b {
  color: #0990d3;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

img.logo {
  display: block;
  max-width: 300px;
  margin-top: 10px;
  margin-left: auto;
}

img.logo-icon {
  position: absolute;
  top: 36px;
  right: 15px;
  max-width: 80px;
  transition: 0.5s ease-in-out;
}

.nav-top .logo:hover .logo-icon {
  top: 0px;
  transition: 0.5s ease-in-out;
}

footer img.logo {
  margin: 10px auto;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.form-group.inline {
  width: 100%;
  max-width: 48%;
}

/* //////////////////////////////////////////// */
/*  Nav                                       */
/* //////////////////////////////////////////// */

nav.navbar {
  padding: 10px;
  margin: 0;
  min-height: 40px;
}

nav.bg-custom {
  background-color: #0990d3;
  box-shadow: inset 0 0px 10px -8px #000, inset 0 0px 10px -8px #000;
}

nav .nav-link {
  color: #fff;
  opacity: 0.6;
  transition: 0.3s;
  padding: 20px 10px;
}

nav .nav-link.active {
  opacity: 1;
}

nav .nav-link:hover {
  color: #fff;
  opacity: 1;
  transition: 0.3s;
}

nav .navbar-collapse {
  justify-content: flex-end;
}

nav .navbar-toggler {
  padding: 0;
  border: none;
}

nav.sticky-top {
  font-size: 1.1em;
  transition: all 0.3s ease-in-out;
  background-color: #454545 !important;
  box-shadow: inset 0 0 3px #000, 0px 7px 0 #fff, 0 3px 20px #000;
}

.navbar-brand img {
  max-height: 50px;
}

.hide {
  transition: all 0.3s ease-in-out;
  display: none;
}

.sticky-top .hide {
  transition: all 0.3s ease-in-out;
  display: block;
}

/* //////////////////////////////////////////// */
/*  Sections                                  */
/* //////////////////////////////////////////// */

section {
  width: 100%;
  padding: 70px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section.about {
  align-items: center;
}

section.works,
section.contact {
  position: relative;
  background-color: #0990d3;
  box-shadow: inset 0 0 3px #000;
}

section.dark,
section.projects,
section.breakdown,
section.questions,
section.payment {
  background-color: #454545;
  box-shadow: inset 0 0 3px #000;
}

section.questions {
  overflow-x: hidden;
  padding: 80px 0;
}

section.pricing h1 {
  transition: all 0.3s ease-in-out;
  font-size: 6em;
  background-color: #000;
  color: transparent;
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

/*About------------------------------*/

.about-img img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.about-txt p span {
  color: #eb2f2f;
  text-transform: uppercase;
  font-weight: 600;
}

.about-img,
.about-txt {
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
  max-width: 300px;
}

/*Pricing----------------------------*/

section.breakdown {
  display: block;
}

section.breakdown .container {
  display: block;
  padding: 20px 0px;
}

section.breakdown .edges {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
}

section.breakdown h1,
section.breakdown p {
  position: relative;
  color: #fff;
  z-index: 2;
}

section.breakdown p.angle {
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  font-size: 1.2em;
  padding: 10px;
}

section.breakdown p.angle span {
  font-size: 1.3em;
}

section.breakdown h3,
section.breakdown h4 {
  color: #909090;
}

section.breakdown .edges h1 {
  color: #5b5b5b;
}

section.breakdown h3 span {
  transition: all 0.3s ease-in-out;
}

section.breakdown li {
  transition: all 0.3s ease-in-out;
  font-size: 1.7em;
}

section.breakdown li span {
  text-transform: uppercase;
  color: #3abeff;
}

section.breakdown li:nth-child(1) {
  margin-left: 20%;
  font-size: 3em;
}

section.breakdown li:nth-child(2) {
  margin-left: 10%;
}

section.breakdown li:nth-child(3) {
  margin-left: 20%;
}

section.breakdown li:nth-child(4) {
  margin-left: 30%;
}

section.breakdown li:nth-child(5) {
  margin-left: 40%;
}

section.breakdown li:nth-child(6) {
  margin-left: 50%;
}

svg {
  position: relative;
  padding: 0;
  left: 0;
  width: 100%;
  height: 10vw;
  /* set height to pixels if you want angle to change with screen width */
}

svg:first-of-type {
  margin-top: 20px;
  margin-bottom: -7px;
}

svg:nth-of-type(2) {
  margin-top: -1px;
  -webkit-transform: rotatex(-180deg);
  -moz-transform: rotatex(-180deg);
  -ms-transform: rotatex(-180deg);
  -o-transform: rotatex(-180deg);
  transform: rotatex(-180deg);
}

/*INDEX page --------------------*/
.works .card {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  text-align: center;
  margin: 20px;
  border: 2px solid #fff;
  outline: 10px solid rgba(255, 255, 255, 0.25);
  transition: 0.3s ease-in-out;
}

/*PROJECTS cards -----------------*/
.projects .card {
  flex: 1;
  min-width: 500px;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 0 15px #000;
  margin: 20px;
  transition: 0.3s ease-in-out;
}

.projects .card-img {
  width: 100%;
  min-width: 190px;
  border-radius: 3px;
  box-shadow: 0 0 10px #555;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  filter: saturate(0.5);
}

.projects p.pop {
  color: red;
  margin: 0;
}

.card-img-top {
  width: 100px;
  margin: 30px auto;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.card-skills {
  width: 100%;
  max-width: 170px;
  padding: 10px;
  margin: 0 10px;
  border: none;
  text-align: center;
  transition: 0.3s ease-in-out;
}

.card-skills .status {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.card-skills:hover .status {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.light .card-skills:hover {
  box-shadow: 0 0 20px -5px #aaa;
  transition: 0.3s ease-in-out;
}

.dark .card-skills:hover {
  box-shadow: 0 0 20px -5px #000;
  transition: 0.3s ease-in-out;
}

/*Form---------------------------*/

.contact form {
  position: relative;
  padding: 20px;
  margin: 10px auto;
  background: #fff;
  border-radius: 3px;
  max-width: 800px;
  outline: 10px solid rgba(255, 255, 255, 0.25);
  transition: all 0.3s ease-in-out;
}

.contact form .row {
  padding: 10px;
}

.contact form .btn-danger {
  padding: 10px 30px;
  background-color: #c92f2f !important;
  transition: all 0.3s ease-in-out;
}

.contact h2.angle-90,
.contact h2.angle-180 {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.prj-txt {
  position: relative;
  outline: 10px solid #fff;
  padding: 20px 30px 10px 30px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  box-shadow: inset 0 0 5px #000, 0 0 5px #000;
}

.prj-txt h2 {
  color: #fff;
}

a.prj-link {
  padding: 0 10px;
}

.prj-txt:hover {
  outline: 0px solid #fff;
  background-color: #fff;
  color: #454545;
  box-shadow: 0 0 5px #000;
  text-shadow: 0 0 5px #fff;
}

.prj-txt:after {
  transition: all 0.3s ease-in-out;
  content: "What I can create for you!";
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  margin: 0;
  text-align: center;
  color: #454545;
  font-family: "Fjalla One";
  font-size: 2em;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
}

.prj-txt:hover:after {
  opacity: 1;
}

img.atwork {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

form .col.verify {
  max-width: 140px;
  margin-top: 5px;
}

form .equation {
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  margin: 0 0 20px;
}

form .equation input {
  margin: -10px 10px 0;
  width: 60px;
}

@media screen and (max-width: 1100px) {
  .projects .card {
    min-width: 400px;
  }
}

@media screen and (max-width: 949px) {
  .card-skills {
    max-width: 300px;
    margin: 10px;
  }
}

@media screen and (max-width: 900px) {
  .projects .card {
    min-width: 300px;
  }

  section.breakdown .container {
    padding: 20px;
  }
}

@media screen and (max-width: 700px) {
  .projects .card {
    min-width: 250px;
  }
}

@media screen and (max-width: 600px) {
  a.logo {
    margin-right: auto;
  }
}
/*Rotated questions-------------------*/

@media screen and (min-width: 700px) {
  .contact h2.angle-90,
  .contact h2.angle-90-2,
  .contact h2.angle-180,
  .contact h2.angle-180-2,
  .contact h3.angle-180-3,
  .contact h2.angle-180-4 {
    position: absolute;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }

  .contact h2.angle-90 {
    top: 50px;
    left: -152px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .contact h2.angle-90-2 {
    bottom: 150px;
    right: -160px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .contact h2.angle-180 {
    top: -50px;
    left: -10px;
  }

  .contact h2.angle-180-2 {
    top: -50px;
    left: 205px;
  }

  .contact h3.angle-180-3 {
    color: #909090;
    bottom: -55px;
    right: 10px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .contact h2.angle-180-4 {
    bottom: -60px;
    left: 60px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 699px) {
  .contact h2.angle-90,
  .contact h2.angle-180 {
    position: absolute;
    font-size: 5vw;
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  .contact h2.angle-180 {
    -webkit-transform: translate(260px, -70px);
    -moz-transform: translate(260px, -70px);
    -ms-transform: translate(260px, -70px);
    -o-transform: translate(260px, -70px);
    transform: translate(260px, -70px);
  }

  .contact h2.angle-90-2,
  .contact h2.angle-180-2,
  .contact h3.angle-180-3,
  .contact h2.angle-180-4 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .contact h2.angle-90 {
    -webkit-transform: translate(-5%, -70px);
    -moz-transform: translate(-5%, -70px);
    -ms-transform: translate(-5%, -70px);
    -o-transform: translate(-5%, -70px);
    transform: translate(-5%, -70px);
  }

  .contact h2.angle-180 {
    -webkit-transform: translate(140%, -70px);
    -moz-transform: translate(140%, -70px);
    -ms-transform: translate(140%, -70px);
    -o-transform: translate(140%, -70px);
    transform: translate(140%, -70px);
  }

  section.pricing h1 {
    font-size: 4em;
  }

  section.breakdown li:nth-child(1),
  section.breakdown li:nth-child(2),
  section.breakdown li:nth-child(3),
  section.breakdown li:nth-child(4),
  section.breakdown li:nth-child(5),
  section.breakdown li:nth-child(6) {
    margin-left: 0;
    font-size: 1.5em;
  }

  form .col.verify {
    min-width: 100%;
    margin: 0 0 20px 0;
  }

  form button {
    min-width: 100%;
  }

  .flex-container {
    flex-direction: column;
  }

  .form-group.inline {
    max-width: 100%;
  }
}

/*///////////////////////////////////////*/
/*Footer---------------------------------*/
/*///////////////////////////////////////*/

footer {
  position: relative;
  padding: 50px 0;
}

footer a.logo {
  width: 220px;
  margin: 0 auto;
}

img.footer-logo {
  max-height: 130px;
  display: block;
  filter: saturate(0%);
}

footer ul.links {
  padding: 0;
  width: 100%;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

footer li.link {
  padding: 5px;
  font-size: 1.3em;
  text-transform: uppercase;
}

footer .link a {
  transition: all 0.1s ease-in-out;
  color: #5b5b5b;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-font-smoothing: antialiased;
}

footer .link a:hover {
  transition: all 0.1s ease-in-out;
  display: block;
  color: #0990d3;
  -webkit-transform: scaleY(1.2);
  -moz-transform: scaleY(1.2);
  -ms-transform: scaleY(1.2);
  -o-transform: scaleY(1.2);
  transform: scaleY(1.2);
  -webkit-font-smoothing: antialiased;
}

.bottom {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
}

footer div.donate {
  max-width: 110px;
  margin-right: 20px;
  margin-left: auto;
}

footer div.donate a {
  position: relative;
}

footer .donate h5 {
  float: left;
  font-family: "Fjalla One";
  margin-top: 15px;
  color: #454545;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

footer .donate img {
  width: 50px;
}

footer h5.donuts {
  position: absolute;
  opacity: 0;
  left: -93px;
  color: #454545;
  transition: all 0.3s ease-in-out;
}

h5.donuts:after {
  content: "!";
  position: absolute;
  font-family: "Fjalla One";
  right: -57px;
  color: #454545;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

footer .donate:hover h5.donate {
  opacity: 0;
}

footer .donate:hover h5.donuts {
  opacity: 1;
}

footer .donate:hover h5.donuts:after {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 600px) {
  footer ul.links {
    margin-bottom: 50px;
  }

  footer div.donate {
    margin-right: auto;
    margin-left: auto;
  }
}

/*///////////////////////////////////////*/
/*Donate---------------------------------*/
/*///////////////////////////////////////*/

.donate-popup .modal-header {
  border: none;
}

.donate-popup .modal-content {
  outline: 20px solid rgba(28, 135, 212, 0.48);
  box-shadow: 0 0 15px #000;
}

.donate-popup form {
  max-width: 350px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 30px 30px 30px;
  background: #ffffff;
}

.donate-popup form label,
.donate-popup form p {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.donate-popup form .mb-2 {
  max-width: 150px;
}

.donate-popup form button {
  height: 38px;
}

#error_explanation p {
  color: #b72424;
}

/*///////////////////////////////////////*/
/*Popup                                  */
/*///////////////////////////////////////*/

.popup:not(.show) {
  display: none;
}

.popup.show {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #0000007a;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 100%;
}

.popup-content {
  background: #fff;
  width: 90%;
  max-width: 1400px;
  padding: 1em;
  margin: 5em 0;
  border-radius: 6px;
  box-shadow: 0 0 20px -5px #0000004d;
  max-height: calc(100vh - 10%);
  overflow: auto;
}

.popup a {
  display: block;
  margin-bottom: 15px;
}

.popup .skill-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.popup .skill-icon {
  width: 35px
}

.popup .skill-icon:not(:last-child) {
  margin-right: 10px;
}

/*///////////////////////////////////////*/
/*Carousel                               */
/*///////////////////////////////////////*/

.carousel {
  display: flex;
  justify-content: center;
}

.carousel .slides {
  overflow: hidden;
  display: inline-flex;
}

.carousel .slides .slide {
  transition: .4s ease-in-out;
  margin-right: 0;
}

.carousel .slides .slide.active {
  width: 0;
  min-width: 100%;
  margin-right: 100%;
}

.carousel .slides img {
  width: 100%;
}


.carousel .dots {
  display: inline-flex;
  padding: 8px;
  position: absolute;
  bottom: 2em;
  margin: 0 auto;
  background: #0000005e;
  border-radius: 20px;
  z-index: 200;
}

.carousel .dots .dot {
  padding: 10px;
  margin: 0 5px;
  background: #fff;
  border-radius: 20px;
  cursor: pointer;
}

.carousel .dots .dot:not(.active) {
  opacity: .5;
}

/*///////////////////////////////////////*/
/*Animations                        */
/*///////////////////////////////////////*/

@media screen and (min-width: 700px) {
  .contact form:hover .angle-90 {
    -webkit-transform: translateX(-20px) rotate(-90deg);
    -moz-transform: translateX(-20px) rotate(-90deg);
    -ms-transform: translateX(-20px) rotate(-90deg);
    -o-transform: translateX(-20px) rotate(-90deg);
    transform: translateX(-20px) rotate(-90deg);
  }

  .contact form:hover .angle-90-2 {
    -webkit-transform: translateX(20px) rotate(90deg);
    -moz-transform: translateX(20px) rotate(90deg);
    -ms-transform: translateX(20px) rotate(90deg);
    -o-transform: translateX(20px) rotate(90deg);
    transform: translateX(20px) rotate(90deg);
  }

  .contact form:hover .angle-180,
  .contact form:hover .angle-180-2 {
    -webkit-transform: translate(-15px, -20px);
    -moz-transform: translate(-15px, -20px);
    -ms-transform: translate(-15px, -20px);
    -o-transform: translate(-15px, -20px);
    transform: translate(-15px, -20px);
  }

  .contact form:hover .angle-180-3,
  .contact form:hover .angle-180-4 {
    -webkit-transform: translate(0px, 20px) rotate(180deg);
    -moz-transform: translate(0px, 20px) rotate(180deg);
    -ms-transform: translate(0px, 20px) rotate(180deg);
    -o-transform: translate(0px, 20px) rotate(180deg);
    transform: translate(0px, 20px) rotate(180deg);
  }
}

@media screen and (min-width: 617px) {
  a.logo:hover img.spinner {
    -webkit-transform: rotate(-220deg);
    -moz-transform: rotate(-220deg);
    -ms-transform: rotate(-220deg);
    -o-transform: rotate(-220deg);
    transform: rotate(-220deg);
  }

  section.about:hover .about-img {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  section.about:hover .about-txt {
    max-width: 110px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@media screen and (max-width: 616px) {
  section.about:hover .about-img {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  section.about:hover .about-txt {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

section.about:hover .about-txt span {
  font-size: 1.5em;
}

.works .card:hover {
  outline: 15px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px #000;
}

.works .card:hover .card-img-top {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  filter: hue-rotate(160deg);
}

.card-skills .card-img-top {  
  min-height: 141px;
}

.works .card:hover .pop {
  color: #eb2f2f;
}

.contact form:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  outline: 20px solid rgba(255, 255, 255, 0.25);
}

.contact form .btn-danger:hover {
  padding: 10px 40px;
  background-color: rgb(167, 23, 23) !important;
}

.stripe:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  outline: 20px solid rgba(255, 255, 255, 0.25);
}

.mail-success {
  transition: all 1.5s ease-in-out;
  background: radial-gradient(#026715, #35b54e);
  filter: hue-rotate(360deg);
}
.email-fade {
  transition: none;
}

.email-sent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.success .email-sent {
  background: radial-gradient(#026715, #35b54e);
}

.failed .email-sent {
  background: radial-gradient(#9b2121, #d03939);
}

.email-sent h1 {
  color: #fff;
  font-size: 5vw;
}

.projects .card-img:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 0 10px #333;
  filter: saturate(1);
}

section.breakdown:hover h3 span {
  text-transform: uppercase;
  color: #fff;
}

section.breakdown:hover h3 span:after {
  content: "!";
  color: #fff;
}

@media screen and (max-width: 575px) {
  .popup.show {
    align-items: baseline;
  }

  .popup .popup-content {
    width: 99%;
    margin: 1% 0 0;
    height: 99vh;
    max-height: 99vh;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2em;
  }

  .about-txt {
    padding: 0 20px;
  }

  .contact form .btn-danger {
    display: block;
    margin: 0 auto;
  }
}
